import React, { useState } from "react"

import CtaBtn from "./CtaBtn"
import StyledInput from "./StyledInput"

import { contactFormSetup } from "../form"
import emailjs from "@emailjs/browser"

export const FormContext = React.createContext(null)

const ContactForm = () => {
  let initialFields = {}
  let initialFieldsErrors = {}

  contactFormSetup.map(
    ({ id, value }) => (initialFields = { ...initialFields, [id]: value })
  )

  contactFormSetup.map(
    ({ id, error }) =>
      (initialFieldsErrors = { ...initialFieldsErrors, [id]: error })
  )

  const [formValues, setFormValues] = useState(initialFields)
  const [formErrors, setFormErrors] = useState(initialFieldsErrors)
  const [formStep, setFormStep] = useState(0)

  const renderFields = contactFormSetup.map(item => (
    <StyledInput key={item.id} {...item} provider={FormContext} />
  ))

  const setValue = (id, value) => {
    return setFormValues({ ...formValues, [id]: value })
  }
  const setError = (id, value) => {
    return setFormErrors({ ...formErrors, [id]: value })
  }

  const sendMessage = (form) => {
    setFormValues(initialFields)
    setFormErrors(initialFieldsErrors)

    setFormStep(1)

    emailjs
      .sendForm(
        process.env.GATSBY_EMAILJS_SERVICE,
        process.env.GATSBY_EMAILJS_APPLICATION_TEMPLATE,
        form,
        process.env.GATSBY_EMAILJS_ID
      )
      .then(res => setFormStep(2))
      .catch(err => setFormStep(3))
  }

  const submitForm = e => {
    e.preventDefault()
    const isValid = Object.keys(formErrors).every(key => formErrors[key] === "")

    if (!isValid) return

    return sendMessage(e.target)
  }

  const renderStep = () => {
    switch (formStep) {
      case 0:
        return (
          <>
            <h3 className="contact-form__title">Wyślij nam wiadomość</h3>
            <form className="contact-form__form" onSubmit={submitForm}>
              <div className="contact-form__area d-flex flex-wrap">
                {renderFields}
              </div>
              <div className="d-flex justify-content-end">
                <CtaBtn
                  type="submit"
                  text="Wyślij wiadomość"
                  action={null}
                  _classes="cta-btn--dark"
                />
              </div>
            </form>
          </>
        )

      case 1:
        return (
          <>
            <h3 className="contact-form__title">Trwa wysyłanie...</h3>
            <div className="loading"></div>
          </>
        )
      case 2:
        return (
          <>
            <h3 className="contact-form__title">Wiadomość została wysłana :)</h3>
            <p className="styled-section__text">
          Odpowiedź otrzymasz na podany adres e-mail, bądź skontaktujemy się z Tobą telefonicznie. Staramy się odpowiadać na Wasze wiadomości jak najszybciej jest to możliwe.
        </p>
          </>
        )
      case 3:
        return (
          <>
            <h3 className="contact-form__title">Podczas próby wysłania wiadomości napotkaliśmy problem :(</h3>
              <p className="styled-section__text">
          Natknęliśmy się na problem w komunikacji z serwerem, prosimy o zgłoszenie zaistniałego problemu.
        </p>
          </>
        )
      default:
        return <>
          <h3 className="contact-form__title">Podczas próby wysłania wiadomości napotkaliśmy nieznany problem :(</h3>
              <p className="styled-section__text">
          Natknęliśmy się na nieznany problem, prosimy o zgłoszenie zaistniałego problemu.
        </p></>
    }
  }

  return (
    <FormContext.Provider
      value={{ values: formValues, errors: formErrors, setValue, setError }}
    >
      <div className="contact-form">{renderStep()}</div>
    </FormContext.Provider>
  )
}

export default ContactForm
