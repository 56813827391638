import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Header from "../components/Header"
import Section from "../components/Section"
import Seo from "../components/seo"
import StyledSection from "../components/StyledSection"
import ContactForm from "../components/ContactForm"

const ContactPage = () => (
  <>
    <Seo
      title="Home"
      description={
        "Gdzie najtaniej można zapisać się na naukę jazdy? Sprawdź ofertę w OSK Adept. Wybierz dogodny kurs na prawo jazdy w Tychach i zobacz, jaka jest cena."
      }
    />
    <Header
      title={
        <>
          <span className="d-block header__title--primary">
            Zostań naszym Adeptem
          </span>
          <span className="d-block">Ośrodek szkolenia kierowców,</span>
          numer jeden w Tychach
        </>
      }
    >
      <StaticImage
        className="header__hero"
        src="../images/location.jpg"
        alt="szkoła jazdy"
      />
    </Header>
    <StyledSection>
      <h2 className="styled-section__title">Skontaktuj się z nami</h2>
      <h3 className="styled-section__subtitle">Zadzwoń</h3>
      <div className="d-flex flex-wrap justify-content-start styled-section__group">
        <a className="styled-section__contact" href="tel:+48506631473">
          <strong>Kierownik</strong>
          <br />
          Marta Lesiak
          <br />
          +48 506-631-473
        </a>
        <a className="styled-section__contact" href="tel:+48512652662">
          <strong>Wykładowca</strong>
          <br />
          Paweł Lesiak
          <br />
          +48 512-652-662
        </a>
      </div>
      <h3 className="styled-section__subtitle">Napisz pod adres</h3>
      <a
        className="styled-section__contact"
        href="malto:kontakt@adept.tychy.pl"
      >
        kontakt@adept.tychy.pl
      </a>
      <h3 className="styled-section__subtitle">Odwiedź nas</h3>
      <div className="d-flex flex-wrap justify-content-start styled-section__group">
        <a
          className="styled-section__contact"
          href="https://goo.gl/maps/XTZXHcJXP6hdyQgLA"
          rel="noreferrer"
          target="_blank"
        >
          <strong>Ośrodek szkoleniowy i sale wykładowe:</strong>
          <br />
          ul. Sienkiewicza 1<br />
          43-100 Tychy
        </a>
        <a
          className="styled-section__contact"
          href="https://goo.gl/maps/hCqJ9wGijEMYQwMB6"
          rel="noreferrer"
          target="_blank"
        >
          <strong>Siedziba firmy:</strong>
          <br />
          ul. de Gaulle'a 47/19
          <br />
          43-100 Tychy
        </a>
      </div>
      <h3 className="styled-section__subtitle">Ureguluj wpłatę</h3>
      <p className="styled-section__contact">
        <strong>Numer konta bankowego:</strong>
        <br />
        89 1050 1399 1000 0091 0371 6784
      </p>
    </StyledSection>
    <Section>
      <ContactForm />
    </Section>
  </>
)

export default ContactPage
